a.panel-heading {
    display: block;
}
    
#previewFrame {
    position: relative;

    .panel-body-loading {
        display: none;

        background: rgba(255, 255, 255, .7);

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        > span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            right: 0;

            text-align: center;
        }
    }
    
    &.is-loading {
        .panel-body-loading {
            display: block;
        }
    }
}